import React from "react"
import { Grid, Box } from "@material-ui/core"

const JustLogoHeader = () => {
  return (
    <>
      <div className="title-strip-bg" />

      <Box position="absolute" top={0} left={0} justify="center">
        <Box display="flex" className="bounded-w">
          <Grid
            container
            className="title-strip"
            alignContent="center"
            justify="space-between"
            spacing={2}
          >
            <a href="/">
              <img
                className="header-logo smaller"
                src={
                  "https://mindance-forestry.s3-eu-west-1.amazonaws.com/upload/logo@2x.png"
                }
              />
            </a>
          </Grid>
        </Box>
      </Box>
    </>
  )
}

export default JustLogoHeader
