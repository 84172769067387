import React from "react"
import PropTypes from "prop-types"
import sectionStyles from "src/styles/components/section.module.scss"
import styles from "src/styles/components/jumbotron.module.scss"
import { Grid, Box } from "@material-ui/core"
import cx from "classnames"

export default function Jumbotron({
  section,
  backgroundImage,
  className,
  children,
}) {
  const {
    title,
    description,
    cta_text,
    cta_link,
    image,
    decorator,
    subDescription,
  } = section

  return (
    <Grid
      container
      component="header"
      className={cx(styles.root, sectionStyles.colored, className)}
      style={
        backgroundImage && { backgroundImage: `url("${backgroundImage}")` }
      }
      alignContent="center"
      justify="flex-end"
    >
      <Grid
        className={styles.headingRow}
        alignItems="flex-end"
        justify="flex-end"
        container
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
      >
        <div className={styles.headingContainer}>
          <Grid item xs={12} sm={12}>
            <h1>{title}</h1>
          </Grid>
          <Grid item xs={12} sm={12}>
            <p>{description}</p>
            <p>{subDescription}</p>
          </Grid>
          {cta_link && (
            <Grid item xs={12} sm={12}>
              <a href={cta_link}>
                <button>{cta_text}</button>
              </a>
            </Grid>
          )}
          {children}
        </div>
      </Grid>
      <Box
        component={Grid}
        item
        display={{ xs: "none", sm: "none", md: "block", lg: "block" }}
        md={6}
        lg={6}
        style={{ position: "relative" }}
        alignItems="flex-start"
        justify="flex-start"
        alignContent="flex-start"
      >
        <div
          className={styles.imageContainer}
          style={{ backgroundImage: `url("${image}")` }}
        />
        {decorator && (
          <img alt="badge" className={styles.decorator} src={decorator} />
        )}
      </Box>
    </Grid>
  )
}

Jumbotron.propTypes = {
  section: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    subDescription: PropTypes.string,
    cta_text: PropTypes.string,
    cta_link: PropTypes.string,
    image: PropTypes.string,
    decorator: PropTypes.string,
  }),
  backgroundImage: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
}
