import React from "react"
import Jumbotron from "src/components/jumbotron"
import Layout from "src/components/layout"
import JustLogoHeader from "src/components/marketing/just-logo"
import "src/styles/pages/landing-page.scss"

export default function NoService() {
  return (
    <Layout className="landing-page-root">
      <JustLogoHeader />
      <Jumbotron
        className="marketing-jumbotron"
        section={{
          image:
            "https://mindance-forestry.s3-eu-west-1.amazonaws.com/upload/200306_Mindance_Illus_06@2x.png",
          title: "Dieser Service steht dir derzeit leider nicht zur Verfügung",
          description:
            "Wende dich an deinen Arbeitgeber und frage nach Mindance oder schreibe uns einen E-mail um mehr zu erfahren.",
        }}
      >
        <a href="mailto:info@mindance.de">info@mindance.de</a>
      </Jumbotron>
    </Layout>
  )
}
